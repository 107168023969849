import React from 'react';

class faqPage extends React.Component {
    componentDidMount(){
        window.location.replace('https://bluemail.me/help/');
    }
    render(){
        return <div />
    }
}

export default faqPage;